<template>  
    <div>
        <h4 class="mb-3">Evenements</h4>

        <div class="card shadow shadow-sm">
            <div class="card-body">
                <p class="mb-0">Will be available soonly</p>
            </div>
        </div>
    </div>
</template>

<script setup>

    import {onMounted, ref, defineEmits, defineProps} from "vue";
    import axios from "axios";

    let props = defineProps(["userInfo"]);

    // Manage loading state page
    let loaded = ref(false);

    // Vars
    let events = ref(null);

    onMounted(() => {
        load();
    })

    function load(){
        // axios.get("/api/events")
        //     .then( answer => {
        //         events.value = answer.data;
        //     })
        //     .catch( answer => {

        //     })
    }


</script>